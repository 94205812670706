import { useContext, useMemo } from 'react';
import {
  ProductOrderByInput,
  WorkspaceProductsDocument,
  WorkspaceProductsQuery,
  WorkspaceProductsQueryVariables,
} from 'types/typed-document-nodes';
import { OrganisationContext } from 'contexts/OrganisationContext';
import { QueryHookOptions, useQuery } from '@apollo/client';

type MakeOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export const useWorkspaceProductsQuery = ({
  variables,
  skip,
  ...restOptions
}: QueryHookOptions<WorkspaceProductsQuery, MakeOptional<WorkspaceProductsQueryVariables, 'workspaceId'>> = {}) => {
  const { workspace } = useContext(OrganisationContext);
  const workspaceId = variables?.workspaceId ?? workspace.id;

  return useQuery(WorkspaceProductsDocument, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      workspaceId,
      archived: variables?.archived,
      orderBy: variables?.orderBy ?? ProductOrderByInput.TitleAsc,
    },
    skip: !workspaceId || skip,
    ...restOptions,
  });
};

export const useWorkspaceProducts = (...args: Parameters<typeof useWorkspaceProductsQuery>) => {
  const { data } = useWorkspaceProductsQuery(...args);
  return useMemo(() => {
    return data?.products ?? [];
  }, [data?.products]);
};

export const useProductFilterOptions = (identifier: 'id' | 'sku' = 'id') => {
  const { data } = useWorkspaceProductsQuery();
  const products = data?.products;

  return useMemo(() => {
    return (products ?? [])
      .filter(product => !!product[identifier])
      .map(product => ({
        id: product[identifier] || '',
        title: product.title,
        archived: product.archived,
        sku: product.sku,
      }));
  }, [products, identifier]);
};

export const useProductSkuFilterOptions = () => {
  const productFilterOptions = useProductFilterOptions('sku');
  return useMemo(() => {
    return productFilterOptions.map(o => ({ ...o, title: o.sku ?? '' }));
  }, [productFilterOptions]);
};
